import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@headlessui/react';
import { ChevronDown } from 'assets/harmonic-icons';
import classNames from 'classnames';
import React, { useRef } from 'react';
export const SingleSelect = React.forwardRef(({ selected, open, filterable, filterTerm, setFilterTerm, getLabelFromValue, onArrowDownPress, onArrowUpPress, onEnterPress, dataTestId, icon, placeholder, disabled, minHeight, borderOverrideClasses }, ref) => {
    const inputRef = useRef(null);
    const Icon = icon;
    return (_jsxs(Popover.Button, { onClick: (e) => {
            const input = inputRef.current;
            if (input && filterable) {
                input.focus();
                setTimeout(() => {
                    input.select();
                }, 0);
            }
        }, "data-open": open, as: 'button', "data-testid": dataTestId, disabled: disabled, ref: ref, className: classNames(
        // Base classes
        'w-full flex border-[1.5px] rounded-br30 bg-surface-default', 'px-p50 py-p40 typography-label-default-default text-input-value-default hover:text-input-value-hover cursor-pointer justify-between', !borderOverrideClasses &&
            classNames('border-int-outline-secondary-enabled', 
            // Hover state
            'hover:border-int-outline-secondary-hover hover:bg-int-overlay-secondary-hover', 
            // Active state
            'active:border-int-outline-secondary-pressed', 
            // Focus state
            'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled', 'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled', 
            // Disabled state
            'disabled:border-int-outline-secondary-disabled'), 
        // Hover state
        'hover:text-input-value-hover', 
        // Active state
        'active:border-int-outline-secondary-pressed active:bg-int-overlay-secondary-pressed active:text-input-value-pressed', 
        // Focus state
        'focus-visible:bg-int-overlay-secondary-enabled', 'data-[open=true]:bg-int-overlay-secondary-enabled', 
        // Disabled state
        'disabled:bg-int-overlay-secondary-disabled disabled:text-input-value-disabled', { 'items-center': !minHeight, 'py-50': minHeight }, borderOverrideClasses), style: { minHeight }, children: [_jsxs("div", { className: "flex items-center justify-start", children: [Icon && _jsx(Icon, { className: "w-4 h-4 text-content-weak mr-p40" }), filterable && (_jsx("input", { className: "w-full bg-inherit text-input-value-default typography-label-default-default cursor-pointer", ref: inputRef, value: filterTerm ?? getLabelFromValue?.(selected), onKeyDown: (e) => {
                            e.stopPropagation();
                        }, onKeyDownCapture: (e) => {
                            if (e.key === 'ArrowDown') {
                                onArrowDownPress();
                            }
                            if (e.key === 'ArrowUp') {
                                onArrowUpPress();
                            }
                            if (e.key === 'Enter') {
                                onEnterPress();
                                return;
                            }
                        }, onChange: (e) => {
                            setFilterTerm?.(e.target.value);
                        }, placeholder: placeholder })), !filterable && (_jsx("p", { className: "flex flex-1", children: getLabelFromValue?.(selected) ?? selected }))] }), _jsx(ChevronDown, { width: 16 })] }));
});
