import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ApiConnectionIcon } from 'assets/harmonic-icons';
import IntegrateGmailButton from './IntegrateGmailButton';
import IntegrateLinkedinButton from './IntegrateLinkedinButton';
const IntegrateLinkedinAndGmail = ({ isLinkedinIntegrated, isGmailIntegrated, userConnectionsExist }) => {
    const displayIntegrationflow = !isLinkedinIntegrated || !isGmailIntegrated;
    if (displayIntegrationflow)
        return (_jsxs("div", { className: "flex flex-row items-center justify-between py-p60 pl-p60 pr-p80 bg-surface-nested rounded-br40", "data-testid": "IntegrateButtonsWithoutCard", children: [_jsxs("div", { className: "flex gap-g60", children: [_jsx("div", { className: "min-w-[44px] min-h-[44px] flex flex-row items-center justify-center rounded-br30 bg-surface-sentiment-informative", children: _jsx(ApiConnectionIcon, { applyCurrentColor: false, className: "fill-content-sentiment-informative" }) }), _jsxs("p", { className: "flex items-center", children: ["See your teammates' connections to discover best paths into this company. ", _jsx("br", {}), " Sync your email, calendar, and LinkedIn to take full advantage of connections."] })] }), _jsxs("div", { className: "flex flex-row gap-g40", children: [_jsx(IntegrateGmailButton, { type: "primary", isIntegrated: isGmailIntegrated }), _jsx(IntegrateLinkedinButton, { type: "primary", isIntegrated: isLinkedinIntegrated })] })] }));
    return (_jsx("div", { className: "flex flex-col items-end", children: _jsxs("div", { "data-testid": "IntegrateButtonsInsideCard", className: "mt-4 flex gap-4", children: [_jsx(IntegrateGmailButton, { type: "secondary", isIntegrated: isGmailIntegrated }), _jsx(IntegrateLinkedinButton, { type: "secondary", isIntegrated: isLinkedinIntegrated })] }) }));
};
export default IntegrateLinkedinAndGmail;
