import { jsx as _jsx } from "react/jsx-runtime";
import { datadogRum } from '@datadog/browser-rum';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { useSelector } from 'react-redux';
import { config } from './config';
const getSplitSdkConfig = (key) => ({
    core: {
        authorizationKey: config.SPLIT_API_KEY,
        key
    },
    sync: {
        enabled: false
    },
    impressionListener: {
        logImpression(impressionData) {
            datadogRum.addFeatureFlagEvaluation(impressionData.impression.feature, impressionData.impression.treatment);
        }
    }
});
export const SplitWrapper = ({ children }) => {
    const authState = useSelector((state) => state.auth);
    return (_jsx(SplitFactoryProvider, { config: authState.user && authState.user.email
            ? getSplitSdkConfig(authState.user.email.toLocaleLowerCase())
            : undefined, children: children }));
};
