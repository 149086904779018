import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import StackedLineChart from 'components/Charts/StackedLineChart';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import { useMemo, useState } from 'react';
import { formatMetricsForChart, getColorsForDepartment } from 'utils/chart';
import LineChartV2 from 'components/Charts/LineChartV2';
import HeadcountChange from '../Cards/HeadcountChange';
import DepartmentFilter from './DepartmentFilter';
const TeamGrowthContentLoader = () => {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { "data-testid": "CompanyTeamGrowthLoading", className: "flex flex-row justify-between gap-10 grid-cols-2 mt-5 mb-4", children: [_jsx("div", { className: "flex w-full h-20 py-px animated-box-pulse" }), _jsx("div", { className: "flex w-full" })] }), _jsx("div", { className: "mt-5 h-80 py-14 bg-slate-200 rounded-md animate-pulse" })] }));
};
export var HeadcountMetricType;
(function (HeadcountMetricType) {
    HeadcountMetricType["ALL_TEAM"] = "ALL_TEAM";
    HeadcountMetricType["ADVISOR"] = "ADVISOR";
    HeadcountMetricType["CUSTOMER_SUCCESS"] = "CUSTOMER_SUCCESS";
    HeadcountMetricType["DATA"] = "DATA";
    HeadcountMetricType["DESIGN"] = "DESIGN";
    HeadcountMetricType["ENGINEERING"] = "ENGINEERING";
    HeadcountMetricType["FINANCE"] = "FINANCE";
    HeadcountMetricType["LEGAL"] = "LEGAL";
    HeadcountMetricType["MARKETING"] = "MARKETING";
    HeadcountMetricType["OPERATIONS"] = "OPERATIONS";
    HeadcountMetricType["OTHER"] = "OTHER";
    HeadcountMetricType["PEOPLE"] = "PEOPLE";
    HeadcountMetricType["PRODUCT"] = "PRODUCT";
    HeadcountMetricType["SALES"] = "SALES";
    HeadcountMetricType["SUPPORT"] = "SUPPORT";
})(HeadcountMetricType || (HeadcountMetricType = {}));
const HeadcountMetricLabelMap = {
    [HeadcountMetricType.ALL_TEAM]: 'Entire Team',
    [HeadcountMetricType.ADVISOR]: 'Advisor',
    [HeadcountMetricType.CUSTOMER_SUCCESS]: 'Customer Success',
    [HeadcountMetricType.DATA]: 'Data',
    [HeadcountMetricType.DESIGN]: 'Design',
    [HeadcountMetricType.ENGINEERING]: 'Engineering',
    [HeadcountMetricType.FINANCE]: 'Finance',
    [HeadcountMetricType.LEGAL]: 'Legal',
    [HeadcountMetricType.MARKETING]: 'Marketing',
    [HeadcountMetricType.OPERATIONS]: 'Operations',
    [HeadcountMetricType.OTHER]: 'Other',
    [HeadcountMetricType.PEOPLE]: 'People',
    [HeadcountMetricType.PRODUCT]: 'Product',
    [HeadcountMetricType.SALES]: 'Sales',
    [HeadcountMetricType.SUPPORT]: 'Support'
};
export const formatDataForChart = (tractionMetrics) => {
    let chartData = {};
    chartData = {
        [HeadcountMetricType.ALL_TEAM]: formatMetricsForChart(tractionMetrics?.headcount?.metrics ?? []),
        [HeadcountMetricType.ADVISOR]: formatMetricsForChart(tractionMetrics?.headcountAdvisor?.metrics ?? []),
        [HeadcountMetricType.CUSTOMER_SUCCESS]: formatMetricsForChart(tractionMetrics?.headcountCustomerSuccess?.metrics ?? []),
        [HeadcountMetricType.DATA]: formatMetricsForChart(tractionMetrics?.headcountData?.metrics ?? []),
        [HeadcountMetricType.DESIGN]: formatMetricsForChart(tractionMetrics?.headcountDesign?.metrics ?? []),
        [HeadcountMetricType.ENGINEERING]: formatMetricsForChart(tractionMetrics?.headcountEngineering?.metrics ?? []),
        [HeadcountMetricType.FINANCE]: formatMetricsForChart(tractionMetrics?.headcountFinance?.metrics ?? []),
        [HeadcountMetricType.LEGAL]: formatMetricsForChart(tractionMetrics?.headcountLegal?.metrics ?? []),
        [HeadcountMetricType.MARKETING]: formatMetricsForChart(tractionMetrics?.headcountMarketing?.metrics ?? []),
        [HeadcountMetricType.OPERATIONS]: formatMetricsForChart(tractionMetrics?.headcountOperations?.metrics ?? []),
        [HeadcountMetricType.OTHER]: formatMetricsForChart(tractionMetrics?.headcountOther?.metrics ?? []),
        [HeadcountMetricType.PEOPLE]: formatMetricsForChart(tractionMetrics?.headcountPeople?.metrics ?? []),
        [HeadcountMetricType.PRODUCT]: formatMetricsForChart(tractionMetrics?.headcountProduct?.metrics ?? []),
        [HeadcountMetricType.SALES]: formatMetricsForChart(tractionMetrics?.headcountSales?.metrics ?? []),
        [HeadcountMetricType.SUPPORT]: formatMetricsForChart(tractionMetrics?.headcountSupport?.metrics ?? [])
    };
    Object.keys(chartData).forEach((key) => {
        if (chartData[key]?.length === 0) {
            delete chartData[key];
        }
    });
    return chartData;
};
const CompanyTeamGrowth = ({ companyId }) => {
    const { data } = useQuery(getCompanyMetrics, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only',
        onCompleted: () => {
            setShownDepartments(departmentOptions);
        }
    });
    const companyData = data?.getCompanyById;
    const tractionMetricsData = companyData?.tractionMetrics;
    const chartData = useMemo(() => formatDataForChart(tractionMetricsData), [tractionMetricsData]);
    const departmentOptions = Object.keys(chartData);
    const [shownDepartments, setShownDepartments] = useState([]);
    const departmentExist = departmentOptions.length > 1;
    return (_jsxs("div", { children: [_jsx("p", { className: "typography-title-medium py-p60 border-b border-solid border-border", children: "Team growth" }), !data ? (_jsx(TeamGrowthContentLoader, {})) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "sm:w-2/5 mt-3 mb-5", children: _jsx(HeadcountChange, { companyId: companyId }) }), _jsxs("div", { className: "border border-solid border-border bg-surface-default rounded-br40", children: [_jsxs("div", { className: "p-p60 border-b border-solid border-border flex justify-between items-center", children: [_jsx("p", { className: classNames('typography-label text-content-strong'), children: "Headcount" }), _jsx(DepartmentFilter, { departmentOptions: departmentOptions, shownDepartments: shownDepartments, setShownDepartments: setShownDepartments })] }), _jsxs("div", { className: "px-p60 pt-p60 pb-p80 h-80", children: [!departmentExist && chartData.ALL_TEAM && (_jsx("div", { "data-testid": "CompanyAllHeadcountChart", className: "h-80 pb-p90", children: _jsx(LineChartV2, { yAxisPrefix: "", data: chartData.ALL_TEAM }) })), departmentExist && (_jsx("div", { "data-testid": "CompanyDepartmentHeadcountChart", className: "h-80 pb-p90", children: _jsx(StackedLineChart, { yAxisPrefix: "", datasets: Object.entries(chartData)
                                                .filter(([key]) => shownDepartments.includes(key))
                                                .map(([key, value]) => ({
                                                label: HeadcountMetricLabelMap[key],
                                                data: value,
                                                backgroundColor: getColorsForDepartment(key).background,
                                                borderColor: getColorsForDepartment(key).border
                                            })) }) }))] })] })] }))] }));
};
export default CompanyTeamGrowth;
