import { jsx as _jsx } from "react/jsx-runtime";
import { UserStatusType } from 'interfaces/Auth';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { ACCEPT_PILOT_AGREEMENT_ROUTE, DEFAULT_DASHBOARD_ROUTE } from 'utils/constants';
export const RequireAuth = ({ children, disableAccess = false }) => {
    const auth = useSelector((state) => state.auth);
    const location = useLocation();
    if (auth.isAuthenticated &&
        auth.status === UserStatusType.PENDING_PILOT_AGREEMENT_ACCEPTANCE) {
        return _jsx(Navigate, { to: ACCEPT_PILOT_AGREEMENT_ROUTE });
    }
    if (!auth.isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return _jsx(Navigate, { to: "/login", state: { from: location }, replace: true });
    }
    if (disableAccess) {
        return _jsx(Navigate, { to: DEFAULT_DASHBOARD_ROUTE });
    }
    return children;
};
