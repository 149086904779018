import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import SquarePlaceholder from 'assets/harmonic-icons/square-placeholder';
import Button from 'harmonic-components/Button/Button';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import Select from 'harmonic-components/Select/Select';
import { capitalize, startCase, toLower } from 'lodash';
import { getColorsForDepartment } from 'utils/chart';
const DepartmentFilter = (props) => {
    const { departmentOptions, shownDepartments, setShownDepartments } = props;
    const [departmentInput, setDepartmentInput] = useState('');
    const getSelectedText = () => {
        switch (shownDepartments.length) {
            case departmentOptions.length:
                return 'All';
            case 1:
                return `${startCase(toLower(shownDepartments[0]))}`;
            default:
                return `${shownDepartments.length} departments`;
        }
    };
    return (_jsxs("div", { className: "flex flex-row items-center", children: [_jsx("div", { className: "text-content-weak relative whitespace-nowrap pr-p50", children: "By department" }), _jsxs(Select, { multiple: false, selected: getSelectedText(), keepOpenOnSelect: true, dropdownWidth: 250, children: [_jsxs("div", { className: "", children: [_jsx(SearchInput, { value: departmentInput, onChange: setDepartmentInput, fullWidth: true, placeholder: "Filter departments" }), _jsxs("div", { className: "flex flex-row justify-between w-full py-p20 border-b border-solid border-border", children: [_jsx(Button, { label: "Select all", onClick: () => setShownDepartments(departmentOptions), size: "compact", emphasis: "low", type: "secondary" }), _jsx(Button, { label: "Clear", onClick: () => setShownDepartments([]), size: "compact", emphasis: "low", type: "secondary" })] })] }), _jsx("div", { className: "max-h-72 overflow-y-auto w-full", children: departmentOptions.slice(1).map((department //slice to remove ALL option
                        ) => {
                            if (departmentInput.length > 0 &&
                                !department
                                    .toLowerCase()
                                    .includes(departmentInput.toLowerCase())) {
                                return null;
                            }
                            return (_jsx(ListItem, { variant: ListVariant.default, label: capitalize(startCase(toLower(department))), value: department, onClick: () => {
                                    if (shownDepartments.includes(department)) {
                                        setShownDepartments(shownDepartments.filter((d) => d !== department));
                                    }
                                    else {
                                        setShownDepartments([...shownDepartments, department]);
                                    }
                                }, selected: shownDepartments.includes(department), primaryIcon: () => (_jsx(SquarePlaceholder, { style: {
                                        color: getColorsForDepartment(department)?.background
                                    } })) }, department));
                        }) })] })] }));
};
export default DepartmentFilter;
