import { gql } from '@apollo/client';
import { FullCompanySearch } from './companySearchFragment';
import { FullPeopleSearch } from './peopleSearchFragment';
export const GET_COMPANY_SAVED_SEARCH_RESULTS = gql `
  query GetCompanySavedSearchResults(
    $idOrUrn: String!
    $first: Int!
    $after: String
    $state: SavedSearchStateFilter
    $skipExtended: Boolean = false
    $skipPeople: Boolean = false
    $skipAffinityLists: Boolean = false
    $skipUserConnections: Boolean = false
    $skipTractionMetrics: Boolean = false
    $skipInvestors: Boolean = false
    $skipLists: Boolean = true
    $highlightNew: Boolean = false
    $skipEntityActions: Boolean = true
    $sortField: String
    $sortDescending: Boolean
  ) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      id
      results(
        first: $first
        after: $after
        state: $state
        highlightNew: $highlightNew
        sortField: $sortField
        sortDescending: $sortDescending
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
        edges {
          cursor
          node {
            netNewId
            entity {
              ... on Company {
                ...CompanySearch
              }
            }
          }
        }
      }
    }
  }
  ${FullCompanySearch}
`;
export const GET_PERSON_SAVED_SEARCH_RESULTS = gql `
  query GetPersonSavedSearchResults(
    $idOrUrn: String!
    $first: Int!
    $after: String
    $state: SavedSearchStateFilter
    $skipExtended: Boolean = false
    $highlightNew: Boolean = false
    $sortField: String
    $sortDescending: Boolean
  ) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      id
      results(
        first: $first
        after: $after
        state: $state
        highlightNew: $highlightNew
        sortField: $sortField
        sortDescending: $sortDescending
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
        edges {
          cursor
          node {
            netNewId
            entity {
              ... on Person {
                ...PeopleSearch
              }
            }
          }
        }
      }
    }
  }
  ${FullPeopleSearch}
`;
export const GET_NET_NEW_SAVED_SEARCH_COUNT = gql `
  query GetNetNewSavedSearchCount($idOrUrn: String!) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      id
      results(first: 1, state: NET_NEW) {
        totalCount
      }
    }
  }
`;
const SAVED_SEARCH_BASE_FRAGMENT = gql `
  fragment SavedSearchBaseMetadata on SavedSearch {
    id
    entityUrn
    isPrivate
    name
    searchQuery
    type
    visibleColumns
    localVisibleColumns @client
    search_type: type
  }
`;
const SAVED_SEARCH_FRAGMENT = gql `
  fragment SavedSearchMetadata on SavedSearch {
    ...SavedSearchBaseMetadata
    creator {
      email
      name
      entityUrn
    }
    userOptions {
      isPinned
      lastViewedAt
    }
  }
  ${SAVED_SEARCH_BASE_FRAGMENT}
`;
export const SAVED_SEARCH_SIMPLE_FRAGMENT = gql `
  fragment SavedSearchSimpleMetadata on SavedSearch {
    ...SavedSearchBaseMetadata
    creator {
      entityUrn
    }
    userOptions {
      isPinned
    }
  }
  ${SAVED_SEARCH_BASE_FRAGMENT}
`;
export const GET_SAVED_SEARCH = gql `
  query GetSavedSearch($idOrUrn: String!) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      ...SavedSearchMetadata
    }
  }
  ${SAVED_SEARCH_FRAGMENT}
`;
export const GET_SAVED_SEARCH_SIMPLE = gql `
  query GetSavedSearchSimple($idOrUrn: String!) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      ...SavedSearchSimpleMetadata
    }
  }
  ${SAVED_SEARCH_SIMPLE_FRAGMENT}
`;
export const GET_SAVED_SEARCH_MOCKED_RESPONSE = {
    getSavedSearch: {
        id: 88001,
        entityUrn: 'urn:harmonic:saved_search:88001',
        isPrivate: true,
        name: '12345',
        searchQuery: {
            filterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: []
            },
            pagination: {
                start: 0,
                pageSize: 25
            },
            sort: [
                {
                    sortField: 'featured',
                    descending: true
                }
            ]
        },
        type: 'COMPANIES_LIST',
        creator: {
            email: 'austin@harmonic.ai',
            name: 'Austin Berke',
            entityUrn: 'urn:harmonic:user:357',
            __typename: 'User'
        },
        userOptions: {
            isPinned: false,
            lastViewedAt: null,
            __typename: 'SavedSearchUserOptions'
        },
        search_type: 'COMPANIES_LIST',
        __typename: 'SavedSearch'
    }
};
