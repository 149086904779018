import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SearchType } from '__generated__/graphql';
import { CircleCheckIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components/Badge';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import useDashboardLocation, { EntityType } from 'hooks/useDashboardLocation';
import analytics, { CustomTrackEvent } from 'utils/analytics';
const NetNewBadge = ({ netNewId }) => {
    const { onClearNetNewById } = useNetNewCounts();
    const { entityType, urn } = useDashboardLocation();
    if (!netNewId || !urn || !entityType)
        return null;
    const searchType = entityType === EntityType.COMPANY
        ? SearchType.COMPANIES_LIST
        : SearchType.PERSONS;
    return (_jsxs("div", { className: "ml-[28px] flex mt-g30 group", children: [_jsx("div", { className: "flex group-hover:hidden", children: _jsx(Badge, { label: "New", color: "expressive-37", intensity: "subtle" }) }), _jsx("div", { className: "hidden group-hover:flex cursor-pointer", onClick: () => {
                    if (!netNewId || !urn)
                        return;
                    onClearNetNewById({
                        id: netNewId,
                        savedSearchUrn: urn,
                        type: searchType
                    });
                    analytics.trackCustomEvent({
                        event: CustomTrackEvent.DISMISS_NET_NEW_ENTITY_CLICKED,
                        properties: {
                            savedSearchUrn: urn,
                            type: searchType
                        }
                    });
                }, children: _jsx(Badge, { leadingIcon: CircleCheckIcon, label: "Dismiss", color: "expressive-37", intensity: "subtle" }) })] }));
};
export default NetNewBadge;
