import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Popover } from '@material-ui/core';
import { PlusIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListType, ListVariant } from 'harmonic-components/ListItem/ListItem';
import useAddPeopleToList from 'hooks/Watchlists/useAddPeopleToWatchlist';
import useRemovePeopleFromList from 'hooks/Watchlists/useRemovePeopleFromWatchlist';
import { useAddEntityListModal } from 'hooks/useAddEntityListModal';
import { EntityListType, INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { compact } from 'lodash';
import { GET_PEOPLE_WATCHLISTS } from 'queries/getPeopleWatchlists';
import { GET_WATCHLISTS_FROM_PEOPLE } from 'queries/getWatchlistsFromPeople';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { HarmonicLoader } from '../ResultsWrapper/LoadingOverlay';
const TogglePersonListsDropdown = ({ personIds, open = false, anchorEl, onClose, anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
}, inverted, className = '-mt-p30', variant = ListVariant.checkboxes, onClick = Function.prototype }) => {
    const { addPeopleToList } = useAddPeopleToList();
    const { removePeopleFromList } = useRemovePeopleFromList();
    const [loadingKeys, setLoadingKeys] = useState([]);
    const { data: peopleWatchlists, loading } = useQuery(GET_PEOPLE_WATCHLISTS);
    const authState = useSelector((state) => state.auth);
    const { data: watchlistsFromPeople } = useQuery(GET_WATCHLISTS_FROM_PEOPLE, {
        variables: {
            ids: personIds,
            extended: false
        }
    });
    const [filterTerm, setFilterTerm] = useState('');
    const currentUser = authState.userMetadata?.user_urn ?? '';
    const onPersonListCreatedSuccess = async ({ entityListId }) => {
        if (variant === ListVariant.default) {
            await onClick(entityListId);
            return;
        }
        await addPeopleToList(entityListId, personIds, true);
    };
    const { showAddEntityListModal: showAddPersonListModal } = useAddEntityListModal({
        disableNavigation: variant === ListVariant.default,
        entityType: EntityListType.PEOPLE_WATCHLIST,
        searchQuery: INITIAL_SEARCH_MODEL,
        onSuccess: onPersonListCreatedSuccess,
        disableToast: variant === ListVariant.default
    });
    const watchlists = useMemo(() => peopleWatchlists?.getPeopleWatchlistsForTeam ?? [], [peopleWatchlists?.getPeopleWatchlistsForTeam]);
    const filteredWatchlist = useMemo(() => compact(watchlists).filter((list) => list.name.toLowerCase().includes(filterTerm.toLowerCase())), [watchlists, filterTerm]);
    const getCommonWatchlistsContainingPeople = useCallback(() => {
        const people = watchlistsFromPeople?.getPersonsByIds ?? [];
        if (people.length === 0) {
            return [];
        }
        const commonWatchlists = people[0]?.watchlists;
        return people.reduce((common, current) => {
            const currentWatchlists = current?.watchlists;
            return common?.filter((watchlist) => currentWatchlists?.some((current) => current?.id === watchlist?.id));
        }, commonWatchlists);
    }, [watchlistsFromPeople?.getPersonsByIds]);
    const selectedOptions = useMemo(() => getCommonWatchlistsContainingPeople()?.map((watchlist) => watchlist?.id ?? '') ?? [], [getCommonWatchlistsContainingPeople]);
    const onChange = async (key) => {
        setLoadingKeys((prev) => {
            return prev.concat(key);
        });
        try {
            if (selectedOptions.includes(key)) {
                await removePeopleFromList(key, personIds);
            }
            else {
                await addPeopleToList(key, personIds);
            }
        }
        finally {
            setLoadingKeys((prev) => {
                return prev.filter((k) => k !== key);
            });
        }
    };
    return (_jsx(Popover, { open: open, anchorEl: anchorEl, onClose: onClose, anchorOrigin: anchorOrigin, elevation: 0, className: "mt-1", classes: {
            paper: 
            // HACK: Need to add padding to we create room for the children shadow to show
            // Otherwise it gets cut off
            classNames('rounded-none bg-transparent border-transparent p-p30 -ml-p30', className)
        }, disableAutoFocus: true, disableEnforceFocus: true, children: _jsx("div", { className: classNames('w-64 overflow-hidden', !inverted && 'visible-scrollbar', inverted && 'inverted-scrollbar'), children: _jsxs(Dropdown, { onFilterTermChange: (value) => setFilterTerm(value), filterTerm: filterTerm, filterPlaceholder: "Search", inverted: inverted, children: [_jsxs("div", { className: classNames('overflow-y-scroll py-p20 w-full', inverted && 'text-white'), children: [loading && _jsx(HarmonicLoader, { showText: false }), filteredWatchlist.map((list, index) => {
                                const key = list.id;
                                const listItemProps = {
                                    type: inverted ? ListType.inverted : ListType.default,
                                    label: list.name,
                                    value: key,
                                    selected: selectedOptions?.includes(key),
                                    onClick: () => onClick(key),
                                    loading: loadingKeys.includes(key),
                                    disabled: loadingKeys.includes(key)
                                };
                                if (variant === ListVariant.default) {
                                    return (_createElement(ListItem, { ...listItemProps, key: `${key}-${index}`, variant: ListVariant.default, onClick: () => onClick(key) }));
                                }
                                return (_createElement(ListItem, { ...listItemProps, key: `${key}-${index}`, variant: variant, onChange: () => onChange(key) }));
                            })] }), _jsx("div", { className: classNames('w-full h-[2px]', !inverted && 'bg-border', inverted && 'bg-border-inverted-weak') }), _jsx(ListItem, { type: inverted ? ListType.inverted : ListType.default, variant: ListVariant.default, primaryIcon: PlusIcon, label: "Add to new list", onClick: showAddPersonListModal, value: "add-to-new-list" })] }) }) }));
};
export default TogglePersonListsDropdown;
