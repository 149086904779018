import { INITIAL_COMPANY_SEARCH_SORT_QUERY } from 'interfaces/SearchModel/Search';
import { create } from 'zustand';
import { useShallowStoreGeneric } from './util';
const useStore = create((set) => ({
    hiddenSearches: [],
    isCustomizeColumnModalOpen: false,
    isSearchQueryBuilderOpen: false,
    shouldRefreshHeaderSavedSearch: false,
    logging: {},
    didChangeRoute: false,
    disableHotkeys: false,
    originalColumns: [],
    pageTitle: '',
    previousPageTitle: '',
    tableColumns: [],
    isUpgradeToProModalOpen: false,
    userUrn: '',
    customerUrn: '',
    companyDashboardPageSearchSort: INITIAL_COMPANY_SEARCH_SORT_QUERY,
    initialSearchModel: {},
    activeTabSearchModelFromApi: {},
    savedSearchModelLoading: true,
    userSettings: {},
    isAffinityFieldsDrawerOpen: false,
    affinityFieldsDrawerCompanyId: -1,
    netNewTab: undefined,
    companyListSelectedNamedView: {},
    personListSelectedNamedView: {},
    editStoreData: (key, payload) => set((state) => ({
        ...state,
        [key]: payload
    })),
    displayUpgradeModal: (permission) => set((state) => ({
        ...state,
        isUpgradeToProModalOpen: true,
        upgradeModalMode: permission
    })),
    displayAffinityFieldsDrawer: (companyId) => {
        set((state) => ({
            ...state,
            isAffinityFieldsDrawerOpen: true,
            affinityFieldsDrawerCompanyId: companyId
        }));
    },
    setLogging: (logging) => {
        set((state) => ({
            ...state,
            logging: {
                ...state.logging,
                ...logging
            }
        }));
    },
    resetLogging: () => {
        set((state) => ({
            ...state,
            logging: undefined
        }));
    }
}));
// Only re-render the component when the selected keys change
export const useShallowStore = (keys) => useShallowStoreGeneric(useStore, keys);
export default useStore;
