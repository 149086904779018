import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { generateFocusClasses, generateInvertedTypeClasses, generateNegativeTypeClasses, generatePrimaryTypeClasses, generateSecondaryTypeClasses } from '../Button/utils';
const generateSizeClasses = (size) => {
    if (size === 'default')
        return 'p-p40';
    if (size === 'tiny')
        return 'p-p10';
    return 'p-p20';
};
/**
 * IconButton
 * @param stopPropagation - if true, stops the event from bubbling up the DOM tree. Allows you to nest buttons without triggering the parent button's onClick event.
 */
const IconButton = ({ type = 'secondary', emphasis = 'high', size = 'default', isSelected = false, isDisabled = false, icon, onClick, dataTestId, className, stopPropagation, loading }) => {
    let mainClasses = '';
    if (type === 'primary')
        mainClasses = generatePrimaryTypeClasses(isSelected, isDisabled);
    else if (type === 'negative')
        mainClasses = generateNegativeTypeClasses(isSelected, isDisabled);
    else if (type === 'secondary')
        mainClasses = generateSecondaryTypeClasses(emphasis, isSelected, isDisabled);
    else if (type === 'inverted')
        mainClasses = generateInvertedTypeClasses(emphasis, isSelected, isDisabled);
    const sizeClasses = generateSizeClasses(size);
    const focusClasses = generateFocusClasses(type);
    const MainIcon = icon;
    return (_jsxs("button", { "data-testid": dataTestId, onClickCapture: stopPropagation
            ? (e) => {
                onClick(e);
                e.stopPropagation();
            }
            : undefined, className: classNames(mainClasses, sizeClasses, focusClasses, 
        //border radius and font sizes are same in all variations. Change it if needed
        'rounded-br20 relative', 'typography-label', 'flex items-center', 'outline-none focus:outline-none active:outline-none', className), disabled: isDisabled, onClick: onClick, children: [loading && (_jsx("div", { className: "absolute inset-0 flex justify-center items-center", children: _jsx(CircularProgress, { classes: {
                        root: 'text-inherit'
                    }, size: 16 }) })), _jsx("span", { className: classNames({
                    'p-p10': size !== 'tiny',
                    'text-transparent': loading
                }), children: _jsx(MainIcon, { className: "w-4 h-4" }) })] }));
};
export default IconButton;
