import { jsx as _jsx } from "react/jsx-runtime";
import { usePerformanceLogging } from '../../../../hooks/usePerformanceLogging';
import { LOADING_ROW_ID } from '../../../../utils/constants';
import { defaultColDef } from '../../../../utils/grid';
import { CellRenderers, LoadingCellRenderer } from './CellRenderers';
import { useIsExtendedLoading } from './hooks/useIsExtendedLoading';
export const CommonCellRenderer = (props) => {
    const { logGridFirstDataRendered, logGridFirstExtendedDataRendered } = usePerformanceLogging();
    const isExtendedLoading = useIsExtendedLoading(props);
    const { column, headers, header, data, rowIndex } = props;
    const cellRendererName = header.cellRenderer || defaultColDef.cellRenderer;
    const field = column?.getColDef().field;
    const isExtended = field && !headers[field];
    if ((isExtended && isExtendedLoading) ||
        (!isExtended && !data) ||
        data?.id === LOADING_ROW_ID) {
        return _jsx(LoadingCellRenderer, {});
    }
    if (rowIndex === 0 && data) {
        if (!isExtended) {
            logGridFirstDataRendered();
        }
        else {
            logGridFirstExtendedDataRendered();
        }
    }
    const Component = CellRenderers[cellRendererName];
    if (!Component)
        return null;
    return _jsx(Component, { ...props });
};
