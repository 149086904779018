import * as React from 'react';
/*
 * Accepts a function that handles closing a component whenever anywhere in the backdrop is clicked.
 * It returns a ref to be passed to the component/modal.
 * This can be used to control hiding modals/popups or dialogues.
 */
export const useOutsideClick = (closeComponent) => {
    const componentToCloseRef = React.useRef(null);
    const handleClose = React.useCallback((e) => {
        const componentToClose = componentToCloseRef.current;
        if (!componentToClose ||
            !componentToClose?.contains(e.target)) {
            closeComponent();
        }
    }, [closeComponent]);
    React.useEffect(() => {
        window.addEventListener('mousedown', handleClose);
        return () => window.removeEventListener('mousedown', handleClose);
    }, [handleClose]);
    return componentToCloseRef;
};
export const useDocumentTitle = (title) => {
    React.useMemo(() => {
        document.title = title;
    }, [title]);
};
