import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { isObject, isString } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { MultiSelect } from './MultiSelect';
import SelectAddOption from './SelectAddOption';
import SelectContext from './SelectContext';
import { SingleSelect } from './SingleSelect';
// Extracts all the list items from props.children
export const getListItemsFromChildren = (children) => {
    let values = [];
    React.Children.forEach(children, (child) => {
        if (!isObject(child))
            return;
        const childElement = child;
        if (!isString(childElement.type) &&
            //eslint-disable-next-line
            //@ts-ignore
            childElement.type.displayName === 'SelectListItem') {
            values.push(childElement.props);
        }
        if (childElement.props && childElement.props.children) {
            values = [
                ...values,
                ...getListItemsFromChildren(childElement.props.children)
            ];
        }
    });
    return values;
};
const Select = (props) => {
    const [keyboardFocusIndex, setKeyboardFocusIndex] = useState(0);
    const [listItems, setListItems] = useState([]);
    const initialized = useRef(false);
    const { onRemove, multiple, selected, filterable, filterTerm, onFilterTermChange, getLabelFromValue, placeholder, children, initialFocus, fullWidth = false, minHeight, dropdownWidth, dataTestId, dropdownDataTestId, disabled, alwaysOpen, scrollDropdownIntoView, borderOverrideClasses, clampValues, inputRef } = props;
    const [popperElement, setPopperElement] = useState(null);
    const buttonRef = useRef();
    const { styles, attributes } = usePopper(buttonRef.current, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 2]
                }
            },
            {
                name: 'recalculate when height changes',
                options: buttonRef?.current?.clientHeight
            }
        ]
    });
    useEffect(() => {
        if (initialFocus && buttonRef && !initialized.current) {
            buttonRef?.current?.click();
            initialized.current = true;
        }
    }, [buttonRef, initialFocus]);
    useEffect(() => {
        const listItems = getListItemsFromChildren(children);
        setListItems(listItems);
    }, [children]);
    useEffect(() => {
        setKeyboardFocusIndex(0);
    }, [filterTerm]);
    const onArrowDownPress = () => {
        if (keyboardFocusIndex < listItems.length - 1) {
            setKeyboardFocusIndex(keyboardFocusIndex + 1);
        }
    };
    const onArrowUpPress = () => {
        if (keyboardFocusIndex > 0) {
            setKeyboardFocusIndex(keyboardFocusIndex - 1);
        }
    };
    const onEnterPress = () => {
        if (listItems.length > 0) {
            const item = listItems?.[keyboardFocusIndex];
            if (!item)
                return;
            // TODO: Update the ListItem type to have just "onClick" for uniformity
            if (item.variant === ListVariant.checkboxes) {
                //eslint-disable-next-line
                //@ts-ignore
                item?.onChange();
            }
            else {
                item?.onClick();
            }
        }
        if (multiple && props.freeSolo && filterTerm) {
            props.onAdd?.(filterTerm);
        }
    };
    const dropdownProps = {
        maxHeight: props.dropdownMaxHeight,
        dataTestId: dropdownDataTestId,
        scrollIntoView: scrollDropdownIntoView
    };
    if (multiple) {
        return (_jsx(Popover, { className: classNames('relative', {
                'w-full': fullWidth
            }), children: ({ open, close }) => {
                return (_jsxs(SelectContext.Provider, { value: { open, close, keyboardFocusIndex, listItems }, children: [_jsx(MultiSelect, { minHeight: minHeight, selected: selected, open: alwaysOpen || open, close: () => {
                                close();
                                if (props.onClose) {
                                    props.onClose();
                                }
                            }, onRemove: onRemove, filterable: filterable, filterTerm: filterTerm, setFilterTerm: onFilterTermChange, getLabelFromValue: getLabelFromValue, getTagColorFromValue: props.getTagColorFromValue, getTagIconFromValue: props.getTagIconFromValue, onArrowDownPress: onArrowDownPress, onArrowUpPress: onArrowUpPress, onEnterPress: onEnterPress, labelPrefix: props.labelPrefix, freeSolo: props.freeSolo, onAdd: props.onAdd, placeholder: placeholder, dataTestId: dataTestId, ref: buttonRef, hideChevronDown: props.hideChevronDown, disabled: disabled, borderOverrideClasses: borderOverrideClasses, clampValues: clampValues, externalInputRef: inputRef }), !props.hideDropdown && (_jsx(Popover.Panel, { static: alwaysOpen, className: classNames('absolute z-10', !dropdownWidth && 'w-full'), ref: setPopperElement, style: {
                                ...styles.popper,
                                width: dropdownWidth ? `${dropdownWidth}px` : undefined
                            }, ...attributes.popper, children: _jsxs(Dropdown, { ...dropdownProps, children: [children, props.onAddNewOption && filterTerm && (_jsx(SelectAddOption, { filterTerm: filterTerm, onAddNewOption: props.onAddNewOption, borderTop: children != '' }))] }) }))] }));
            } }));
    }
    return (_jsx(Popover, { className: classNames('relative', {
            'w-full': fullWidth
        }), children: ({ open, close }) => {
            return (_jsxs(SelectContext.Provider, { value: { open, close, keyboardFocusIndex, listItems }, children: [_jsx(SingleSelect, { minHeight: minHeight, selected: selected, open: alwaysOpen || open, filterable: filterable, filterTerm: filterTerm, setFilterTerm: onFilterTermChange, getLabelFromValue: getLabelFromValue, onArrowDownPress: onArrowDownPress, onArrowUpPress: onArrowUpPress, onEnterPress: onEnterPress, placeholder: placeholder, dataTestId: dataTestId, icon: props.icon, disabled: disabled, ref: buttonRef, borderOverrideClasses: borderOverrideClasses }), !props.hideDropdown && (_jsx(Popover.Panel, { static: alwaysOpen, className: classNames('absolute z-10', !dropdownWidth && 'w-full'), ref: setPopperElement, style: {
                            ...styles.popper,
                            width: dropdownWidth ? `${dropdownWidth}px` : undefined
                        }, ...attributes.popper, children: _jsx(Dropdown, { ...dropdownProps, children: React.Children.map(children, (child) => {
                                return (_jsx("div", { onClick: () => {
                                        if (props.keepOpenOnSelect)
                                            return;
                                        close();
                                    }, className: "w-full", children: child }));
                            }) }) }))] }));
        } }));
};
export default Select;
