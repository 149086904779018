import useResizeObserver from '@react-hook/resize-observer';
import { useState } from 'react';
export const useIsClamped = ({ contentRef }) => {
    const [isClamped, setClamped] = useState(false);
    // Listen to resiizing of the component
    useResizeObserver(contentRef.current, () => {
        if (contentRef && contentRef.current) {
            setClamped(contentRef.current.scrollHeight > contentRef.current.clientHeight);
        }
    });
    return { isClamped };
};
