import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { useIsClamped } from 'hooks/useIsClamped';
import React, { useMemo, useRef, useState } from 'react';
import useFlags from '../../hooks/useFlags';
import { SPLITS } from '../../utils/constants';
import { TruncatedListV2 } from './TruncatedListV2';
const LineclampButton = ({ numClamped, handleExpand, className }) => {
    return (_jsx("div", { className: className, children: _jsx("button", { onClick: handleExpand, className: classNames('w-min whitespace-nowrap rounded-br20 flex gap-g10 items-center justify-center', 'py-p10 px-p30 typography-label-default-default', 'cursor-pointer', 'text-content-sentiment-neutral bg-surface-sentiment-neutral'), children: _jsx("p", { className: "line-clamp-1", children: `+${numClamped}` }) }) }));
};
const TruncatedList = ({ children, height, divider, lineclampButton = (props) => _jsx(LineclampButton, { ...props }), wrapperClassName, contentClassName }) => {
    const contentRef = useRef(null);
    const lastVisibleChipRef = useRef(null);
    const DividerElement = divider;
    const { enabled: enabledTruncatedListV2 } = useFlags(SPLITS.enableTruncatedListV2);
    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { visibilityMap } = useIntersectionObserver({ contentRef });
    const { isClamped } = useIsClamped({ contentRef });
    const handleExpand = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setExpanded(true);
    };
    const handleClose = (event) => {
        event.preventDefault();
        setExpanded(false);
        setAnchorEl(null);
    };
    // Update the number of clamped chips and the last visible chip whenever the visibility map updates
    const numClamped = useMemo(() => {
        return Object.keys(visibilityMap).reduce((acc, key) => {
            if (!visibilityMap[key]) {
                return (acc += 1);
            }
            else {
                return (acc += 0);
            }
        }, 0);
    }, [visibilityMap]);
    const childrenArray = React.Children.toArray(children);
    return (_jsxs("div", { className: classNames('flex flex-row justify-start', wrapperClassName), children: [enabledTruncatedListV2 ? (_jsx(TruncatedListV2, { className: "flex items-start flex-wrap gap-g30", renderTruncator: ({ hiddenItemsCount }) => {
                    return lineclampButton({
                        numClamped: hiddenItemsCount,
                        handleExpand
                    });
                }, style: { height: height + 4 }, children: children })) : (_jsxs(_Fragment, { children: [_jsx("div", { ref: contentRef, className: classNames(`overflow-hidden flex items-start flex-wrap gap-g30`, contentClassName), style: { height }, children: childrenArray.map((child, index) => {
                            const isLastVisibleChip = childrenArray.length - index - 1 == numClamped;
                            return (_jsxs("div", { ref: isLastVisibleChip ? lastVisibleChipRef : null, "data-targetid": `truncated-list-item-${index}`, className: "flex gap-g30", children: [child, DividerElement &&
                                        !isLastVisibleChip &&
                                        index < childrenArray.length - 1 &&
                                        DividerElement] }, `truncated-list-item-${index}`));
                        }) }), isClamped &&
                        numClamped > 0 &&
                        lineclampButton({
                            numClamped,
                            handleExpand,
                            className: 'h-[26px] w-[55px] flex shrink-0 flex-col justify-end items-end'
                        })] })), _jsx(Popover, { open: expanded, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }, className: "ml-p30 overflow-hidden bg-none rounded-br40 visible-scrollbar", PaperProps: {
                    style: {
                        borderRadius: 6
                    }
                }, children: _jsx("div", { "data-testid": "TruncatedListPopover", className: "p-p50 max-h-96 overflow-y-scroll flex flex-col gap-y-g40 whitespace-nowrap bg-surface-default shadow-static-elevation-floating border-[1.5px] border-solid border-border rounded-br30", children: children }) })] }));
};
export default TruncatedList;
