import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlagIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import Modal from 'harmonic-components/Modal/Modal';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import TextArea from 'harmonic-components/TextArea/TextArea';
import { useState } from 'react';
import { submitDataIssue } from 'utils/hasuraApi';
import { displayToast } from 'utils/toasts';
export const ReportDataIssueLegalDisclaimer = (props) => {
    const { className } = props;
    return (_jsx("p", { className: classNames(className, 'typography-label-small-default text-content-weak'), children: "This feedback may be used to correct or verify our data." }));
};
const ReportDataIssue = (props) => {
    const { reportParams } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userInput, setUserInput] = useState('');
    const onClose = () => {
        setDialogOpen(false);
        setUserInput('');
    };
    const [loading, setLoading] = useState(false);
    const onSubmit = async () => {
        setLoading(true);
        try {
            submitDataIssue({ ...reportParams, reportText: userInput });
            displayToast({
                primaryText: 'Report submitted successfully',
                mode: 'success'
            });
        }
        catch (e) {
            displayToast({
                primaryText: 'There was an error submitting report',
                mode: 'error'
            });
        }
        finally {
            setLoading(false);
            onClose();
        }
    };
    return (_jsxs("div", { "data-testid": "ReportDataIssue", children: [_jsx(IconButton, { icon: FlagIcon, onClick: () => setDialogOpen(true), type: "secondary", emphasis: "high", size: "default", dataTestId: "ReportDataIssue-Button" }), _jsxs(Modal, { isOpen: dialogOpen, onClose: onClose, children: [_jsx(ModalTitle, { title: "Suggest a correction" }), _jsxs("div", { "data-testid": "ReportDataIssue-Popover", className: "p-p40", children: [_jsx(TextArea, { value: userInput, onChange: (e) => setUserInput(e.target.value), placeholder: props.placeholderText }), _jsx(ReportDataIssueLegalDisclaimer, { className: "pl-p10 pt-p20" })] }), _jsx(ModalFooter, { children: _jsx(Button, { dataTestId: "ReportDataIssue-Submit-Button", type: "primary", label: "Submit", isDisabled: loading, loading: loading, onClick: onSubmit }) })] })] }));
};
export default ReportDataIssue;
