import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable testing-library/render-result-naming-convention */
import { DRAWER_WIDTH } from 'components/Dashboard/DashboardLayout/styles';
import { CommonCellRenderer } from '../CellRenderers/CommonCellRenderer';
import { formatHeadersForCellRenderers } from './utils';
const CommonPeopleHeaders = [
    {
        field: 'person.id',
        headerName: 'ID',
        hide: true,
        sortable: false,
        searchModelSortable: false,
        searchModelIdentifier: 'person_id',
        toggleable: true,
        width: 100
    },
    {
        field: 'person.fullName',
        headerName: 'Full Name',
        sortable: true,
        searchModelSortable: true,
        searchModelFilterable: true,
        searchModelIdentifier: 'people_sortable_name',
        initialPinned: true,
        headerCheckboxSelection: false,
        headerComponent: 'BulkSelectCheckboxRenderer',
        cellRenderer: 'PersonNameWithCheckboxCellRenderer',
        width: 235,
        minWidth: 235,
        suppressSizeToFit: true,
        sortType: 'text',
        valueGetter: (params) => params.data
    },
    {
        field: 'person.firstName',
        headerName: 'First Name',
        editable: false,
        sortable: false,
        toggleable: true,
        hide: true,
        width: 150
    },
    {
        field: 'person.lastName',
        headerName: 'Last Name',
        editable: false,
        sortable: false,
        toggleable: true,
        hide: true,
        width: 150
    }
];
const _PeopleHeaders = [
    ...CommonPeopleHeaders,
    {
        field: 'person.watchlists',
        headerName: 'Lists',
        editable: false,
        cellRenderer: 'PeopleListsCellRenderer',
        width: 250
    },
    {
        field: 'person.entryCreatedAt',
        headerName: 'Date added to list',
        hide: true,
        sortable: false,
        searchModelSortable: false,
        toggleable: true,
        width: 150,
        cellRenderer: 'DateCellRenderer'
    },
    {
        field: 'person.experience.currentCompany',
        headerName: 'Current Company',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 300,
        cellRenderer: 'PersonExperienceDataCellRenderer',
        cellRendererParams: {
            dataToFetch: 'name',
            timeFrame: 'current'
        },
        valueGetter: (params) => {
            return params.data?.person?.experience;
        }
    },
    {
        field: 'person.experience.currentTitle',
        headerName: 'Current Title',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 300,
        cellRenderer: 'PersonExperienceDataCellRenderer',
        cellRendererParams: {
            dataToFetch: 'title',
            timeFrame: 'current'
        },
        valueGetter: (params) => {
            return params.data?.person?.experience;
        }
    },
    {
        field: 'person.experience.currentDepartment',
        headerName: 'Current Department',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 300,
        cellRenderer: 'PersonExperienceDataCellRenderer',
        cellRendererParams: {
            dataToFetch: 'department',
            timeFrame: 'current'
        },
        valueGetter: (params) => {
            return params.data?.person?.experience;
        }
    },
    {
        field: 'person.location.location',
        headerName: 'Current Location',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 200
    },
    {
        field: 'person.linkedinHeadline',
        headerName: 'LinkedIn Headline',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 200
    },
    {
        field: 'person.experience.currentCompanyTags',
        headerName: 'Current Company Tags',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 300,
        cellRenderer: 'PersonExperienceDataCellRenderer',
        cellRendererParams: {
            dataToFetch: 'tags',
            timeFrame: 'current'
        },
        valueGetter: (params) => {
            return params.data?.person?.experience;
        }
    },
    {
        field: 'person.experience.previousCompany',
        headerName: 'Previous Company',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 300,
        cellRenderer: 'PersonExperienceDataCellRenderer',
        cellRendererParams: {
            dataToFetch: 'name',
            timeFrame: 'previous'
        },
        valueGetter: (params) => {
            return params.data?.person?.experience;
        }
    },
    {
        field: 'person.experience.previousCompanyTags',
        headerName: 'Previous Company Tags',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 300,
        cellRenderer: 'PersonExperienceDataCellRenderer',
        cellRendererParams: {
            dataToFetch: 'tags',
            timeFrame: 'previous'
        },
        valueGetter: (params) => {
            return params.data?.person?.experience;
        }
    },
    {
        field: 'person.experience.previousCompanies',
        headerName: 'Previous Companies',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 500,
        minWidth: 300,
        cellRenderer: 'PersonExperienceDataCellRenderer',
        cellRendererParams: {
            dataToFetch: 'name',
            timeFrame: 'all_previous'
        },
        valueGetter: (params) => {
            return params.data?.person?.experience;
        }
    },
    {
        field: 'person.highlights',
        headerName: 'Person Highlights',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 500,
        minWidth: 400,
        wrapText: true,
        cellStyle: { whiteSpace: 'normal' },
        searchModelFilterable: true,
        searchModelIdentifier: 'person_highlights',
        cellRenderer: 'PeopleHighlightsCellRenderer'
    },
    {
        field: 'person.socials.linkedin.url',
        headerName: 'LinkedIn',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 300,
        cellRenderer: 'LinkCellRenderer'
    },
    {
        field: 'person.education',
        headerName: 'Education',
        toggleable: true,
        width: 500,
        minWidth: 400,
        cellRenderer: 'EducationCellRenderer'
    },
    {
        field: 'person.contact.emails',
        headerName: 'Email',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 400,
        cellRenderer: 'EmailForPeopleSearchCellRenderer'
    },
    {
        field: 'person.correspondenceSummary.lastMeetingAt',
        headerName: 'Last meeting date',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 235,
        cellRenderer: 'DateCellRenderer'
    },
    {
        field: 'person.correspondenceSummary.lastMeetingContactPersonEmail',
        headerName: 'Last meeting with',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 235,
        cellRenderer: 'CorrespondencePersonCellRenderer'
    },
    {
        field: 'person.correspondenceSummary.lastEmailAt',
        headerName: 'Last email date',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 235,
        cellRenderer: 'DateCellRenderer'
    },
    {
        field: 'person.correspondenceSummary.lastEmailContactPersonEmail',
        headerName: 'Last email with',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 235,
        cellRenderer: 'CorrespondencePersonCellRenderer'
    },
    {
        field: 'person.userConnections',
        headerName: 'Team network',
        editable: false,
        sortable: false,
        toggleable: true,
        width: 235,
        cellRenderer: 'UserConnectionsCellRenderer',
        customTooltip: 'See who on your team is in network with this person'
    },
    {
        // Extra empty column to account for expanded sidebar overflow
        headerName: '',
        toggleable: false,
        width: DRAWER_WIDTH, // width of sidebar
        sortable: false,
        hide: false
    }
];
/*
  CommonPeopleHeadersByField is a map of header fields that are returned by the query without extended data.
*/
const CommonPeopleHeadersByField = formatHeadersForCellRenderers({
    headers: CommonPeopleHeaders
});
const PeopleHeaders = _PeopleHeaders.map((header) => {
    return {
        ...header,
        cellRenderer: (params) => (_jsx(CommonCellRenderer, { header: header, headers: CommonPeopleHeadersByField, ...params }))
    };
});
export const PeopleSortableHeaderFields = [
    'person_id',
    ...PeopleHeaders.filter((header) => header.searchModelSortable && header.searchModelIdentifier).map((header) => header.searchModelIdentifier)
];
export default PeopleHeaders;
