import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from 'components/common/ErrorPage';
import LoadingOverlay from 'components/common/ResultsWrapper/LoadingOverlay';
import UpgradeToProModal from 'components/common/UpgradeToProModal';
import Toast from 'harmonic-components/Toast/Toast';
import { useHasChangedRoute } from 'hooks/useHasChangedRoute';
import ModalProvider from 'hooks/useModal';
import AuthActionHandler from 'screens/Auth/AuthAction';
import PilotContractAgreement from 'screens/Auth/PilotContractAgreement/PilotContractAgreement';
import { RequireAuth } from 'screens/Auth/RequireAuth';
import useStore from 'stores/zustandStore';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './styles/App.css';
import { lazyRetry } from './utils/lazyRetry';
const PAGES = {
    'dashboard/*': {
        Component: lazy(() => lazyRetry(() => import('screens/Dashboard'), 'dashboard')),
        preload: () => lazyRetry(() => import('screens/Dashboard'), 'dashboard')
    },
    'settings/*': {
        Component: lazy(() => lazyRetry(() => import('components/Dashboard/UserSettings'), 'user-settings')),
        preload: () => lazyRetry(() => import('components/Dashboard/UserSettings'), 'user-settings')
    },
    'docs/*': {
        Component: lazy(() => lazyRetry(() => import('screens/Docs'), 'docs')),
        preload: () => lazyRetry(() => import('screens/Docs'), 'docs')
    },
    onboarding: {
        Component: lazy(() => lazyRetry(() => import('components/Dashboard/Onboarding/Onboarding'), 'onboarding')),
        preload: () => lazyRetry(() => import('components/Dashboard/Onboarding/Onboarding'), 'onboarding')
    },
    login: {
        Component: lazy(() => lazyRetry(() => import('screens/Auth/Login/LoginV2'), 'login')),
        preload: () => lazyRetry(() => import('screens/Auth/Login/LoginV2'), 'login')
    },
    'pw-login': {
        Component: lazy(() => lazyRetry(() => import('screens/Auth/Login/Login'), 'pw-login')),
        preload: () => lazyRetry(() => import('screens/Auth/Login/Login'), 'pw-login')
    },
    'forgot-password': {
        Component: lazy(() => lazyRetry(() => import('screens/Auth/ForgotPassword'), 'forgot-password')),
        preload: () => lazyRetry(() => import('screens/Auth/ForgotPassword'), 'forgot-password')
    },
    'user-status': {
        Component: lazy(() => lazyRetry(() => import('screens/Auth/UserStatus'), 'user-status')),
        preload: () => lazyRetry(() => import('screens/Auth/UserStatus'), 'user-status')
    },
    'email-verification': {
        Component: lazy(() => lazyRetry(() => import('screens/Auth/EmailVerification'), 'email-verification')),
        preload: () => lazyRetry(() => import('screens/Auth/EmailVerification'), 'email-verification')
    }
};
Object.entries(PAGES).forEach(([, { preload }]) => {
    preload();
});
const Login = PAGES['pw-login'].Component;
const LoginV2 = PAGES['login'].Component;
const ForgotPasswordPage = PAGES['forgot-password'].Component;
const UserStatus = PAGES['user-status'].Component;
const EmailVerification = PAGES['email-verification'].Component;
const Dashboard = PAGES['dashboard/*'].Component;
const UserSettings = PAGES['settings/*'].Component;
const Docs = PAGES['docs/*'].Component;
const Onboarding = PAGES['onboarding'].Component;
const AppLayout = () => {
    const editStoreData = useStore((state) => state.editStoreData);
    const isUpgradeToProModalOpen = useStore((state) => state.isUpgradeToProModalOpen);
    const hasChangedRoute = useHasChangedRoute({
        excludeSearchParams: false
    });
    useEffect(() => {
        if (hasChangedRoute) {
            editStoreData('didChangeRoute', true);
        }
    }, [hasChangedRoute, editStoreData]);
    const upgradeModalMode = useStore((state) => state.upgradeModalMode);
    return (_jsxs(ModalProvider, { children: [_jsx(UpgradeToProModal, { isOpen: isUpgradeToProModalOpen, mode: upgradeModalMode, handleClose: () => editStoreData('isUpgradeToProModalOpen', false) }), _jsx(Toast, {}), _jsx(Suspense, { fallback: _jsx(LoadingOverlay, {}), children: _jsxs(Routes, { children: [_jsx(Route, { path: "login", element: _jsx(LoginV2, {}) }), _jsx(Route, { path: "signup", element: _jsx(Navigate, { to: "login" }) }), _jsx(Route, { path: "pw-login", element: _jsx(Login, {}) }), _jsx(Route, { path: "forgot-password", element: _jsx(ForgotPasswordPage, {}) }), _jsx(Route, { path: "user-status", element: _jsx(UserStatus, {}) }), _jsx(Route, { path: "email-verification", element: _jsx(EmailVerification, {}) }), _jsx(Route, { path: "auth-action", element: _jsx(AuthActionHandler, {}) }), _jsx(Route, { path: "accept-pilot-agreement", element: _jsx(PilotContractAgreement, {}) }), _jsx(Route, { path: "onboarding", errorElement: _jsx(ErrorPage, {}), element: _jsx(RequireAuth, { children: _jsx(Onboarding, {}) }) }), _jsx(Route, { path: "dashboard/*", errorElement: _jsx(ErrorPage, {}), element: _jsx(RequireAuth, { children: _jsx(Dashboard, {}) }) }), _jsx(Route, { path: "settings/*", errorElement: _jsx(ErrorPage, {}), element: _jsx(RequireAuth, { children: _jsx(QueryParamProvider, { adapter: ReactRouter6Adapter, children: _jsx(UserSettings, {}) }) }) }), _jsx(Route, { path: "docs/*", errorElement: _jsx(ErrorPage, {}), element: _jsx(Docs, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "login" }) })] }) })] }));
};
export default AppLayout;
