import { jsx as _jsx } from "react/jsx-runtime";
import { ListCustomFieldType } from '__generated__/graphql';
import SingleDatePicker from 'harmonic-components/DatePicker/SingleDatePicker';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import useTeamMembers from 'hooks/useTeamMembers';
import { isNil } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { formatNumberListCustomFieldValue, isNumberAndNaN, parseNumberListCustomFieldValue } from 'utils/utilities';
import { getNthColor } from '../../../../utils/colors';
import { colorOptions } from '../EditField/SelectOptions/EditOptionItem';
import { LoadingCellRenderer } from './CellRenderers';
import { CustomFieldSelect } from './components/CustomFieldSelect';
export const DEFAULT_HEIGHT = 54;
const CustomFieldSelectListItem = ({ option, onClick, selected }) => {
    const handleClick = useCallback(() => {
        onClick(option);
    }, [onClick, option]);
    return (_jsx(SelectListItem, { label: option.name, value: option.urn, onClick: handleClick, color: option.color, variant: ListVariant.tag, selected: selected }, option.urn));
};
export const CustomColumnSingleSelectCellEditor = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState(props.value);
    const [options, setOptions] = React.useState(props.options);
    const [searchTerm, setSearchTerm] = React.useState('');
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const gridContext = props.context;
    const handleUpdateOptions = useCallback(async (newOption) => {
        const newOptions = options.map(({ ...rest }) => rest);
        const color = getNthColor(colorOptions, newOptions.length);
        if (newOption) {
            newOptions.push({ name: newOption, color: color, urn: undefined });
        }
        if (props.colDef.headerName && gridContext.watchlistUrn) {
            const result = await gridContext.updateCustomField({
                watchlistUrn: gridContext.watchlistUrn,
                customFieldUrn: props.colDef.field,
                name: props.colDef.headerName,
                fieldType: ListCustomFieldType.SINGLE_SELECT,
                metadata: newOptions
            }, {
                updateEditColumnsConfig: false
            });
            const options = (result?.data?.updateCompanyWatchlistCustomField
                .metadata).options;
            setOptions(options);
            setValue(options[options.length - 1].urn);
            setSearchTerm('');
        }
    }, [options, props.colDef, gridContext]);
    const handleClick = useCallback((option) => {
        setValue(option.urn);
        setSearchTerm('');
        setTimeout(() => {
            props.api.stopEditing();
        }, 0);
    }, [props.api]);
    return (_jsx("div", { style: { width }, children: _jsx("div", { className: "flex flex-col gap-g40 bg-surface-default", children: _jsx(CustomFieldSelect, { options: options, api: props.api, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: value ? [value] : undefined, onAddNewOption: handleUpdateOptions, onRemove: () => {
                    setValue(null);
                }, children: filteredOptions.map((option) => {
                    return (_jsx(CustomFieldSelectListItem, { option: option, selected: option.urn === value, onClick: handleClick }, option.urn));
                }) }) }) }));
});
export const CustomColumnPersonSelectCellEditor = React.forwardRef((props, ref) => {
    const { activeTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
    const options = useMemo(() => {
        return (activeTeamMembers?.map((member, i) => ({
            urn: member?.user?.entityUrn,
            name: member?.user?.name,
            color: getNthColor(colorOptions, i)
        })) ?? []);
    }, [activeTeamMembers]);
    const [value, setValue] = React.useState(props.value ?? []);
    const [searchTerm, setSearchTerm] = React.useState('');
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options
        .filter((option) => option?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    const setAdditionalValue = (option) => {
        if (value.includes(option.urn)) {
            setValue(value.filter((v) => v !== option.urn));
        }
        else {
            setValue([...value, option.urn]);
        }
        setSearchTerm('');
    };
    const handeClick = (option) => {
        setAdditionalValue(option);
    };
    if (!activeTeamMembers || activeTeamMembers.length === 0) {
        return _jsx(LoadingCellRenderer, {});
    }
    return (_jsx("div", { style: { width }, children: _jsx("div", { className: "flex flex-col gap-g40 bg-surface-default", children: _jsx(CustomFieldSelect, { options: options, api: props.api, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: value, onRemove: (val) => {
                    setValue(value.filter((v) => v !== val));
                }, children: filteredOptions.map((option) => {
                    return (_jsx(CustomFieldSelectListItem, { option: option, selected: value.includes(option.urn), onClick: handeClick }, option.urn));
                }) }) }) }));
});
export const CustomColumnMultiSelectCellEditor = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState(props.value ?? []);
    const [options, setOptions] = React.useState(props.options);
    const [searchTerm, setSearchTerm] = React.useState('');
    const gridContext = props.context;
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const inputRef = useRef(null);
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const setAdditionalValue = useCallback((option) => {
        if (value.includes(option.urn)) {
            setValue(value.filter((v) => v !== option.urn));
        }
        else {
            setValue([...value, option.urn]);
        }
        setSearchTerm('');
    }, [value]);
    const handleUpdateOptions = useCallback(async (newOption) => {
        const newOptions = options.map(({ ...rest }) => rest);
        const color = getNthColor(colorOptions, newOptions.length);
        newOption
            ? newOptions.push({ name: newOption, color: color, urn: undefined })
            : '';
        if (props.colDef.headerName && gridContext.watchlistUrn) {
            const result = await gridContext.updateCustomField({
                watchlistUrn: gridContext.watchlistUrn,
                customFieldUrn: props.colDef.field,
                name: props.colDef.headerName,
                fieldType: ListCustomFieldType.SINGLE_SELECT,
                metadata: newOptions
            }, {
                updateEditColumnsConfig: false
            });
            const options = (result?.data?.updateCompanyWatchlistCustomField
                .metadata).options;
            setOptions(options);
            setAdditionalValue(options[options.length - 1]);
            setSearchTerm('');
            inputRef?.current?.focus();
        }
    }, [gridContext, options, props.colDef, setAdditionalValue]);
    const handleRemove = useCallback((val) => {
        setValue(value.filter((v) => v !== val));
    }, [value]);
    const handeClick = useCallback((option) => {
        setAdditionalValue(option);
    }, [setAdditionalValue]);
    return (_jsx("div", { style: { width }, children: _jsx("div", { className: "flex flex-col gap-g40 bg-surface-default", children: _jsx(CustomFieldSelect, { options: options, api: props.api, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: value, onAddNewOption: handleUpdateOptions, onRemove: handleRemove, inputRef: inputRef, dataTestId: "CustomColumnMultiSelectCellEditor-Select", children: filteredOptions.map((option) => {
                    return (_jsx(CustomFieldSelectListItem, { option: option, onClick: handeClick, selected: value.includes(option.urn) }, option.urn));
                }) }) }) }));
});
export const CustomColumnTextCellEditor = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState(props.value);
    const refInput = React.useRef(null);
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    React.useEffect(() => {
        if (refInput.current) {
            refInput.current.focus();
            refInput.current.setSelectionRange(refInput.current.value.length, refInput.current.value.length);
        }
    }, [refInput]);
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    return (_jsx("textarea", { onBlur: () => {
            refInput?.current?.focus();
        }, onFocus: (event) => {
            if (event.target) {
                event.target.scrollTop = event.target.scrollHeight;
            }
        }, autoFocus: true, className: "min-h-[77px] bg-surface-default p-p50 rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default", onChange: (e) => setValue(e.target.value), ref: refInput, value: value, style: { width } }));
});
export const CustomColumnNumberCellEditor = React.forwardRef((props, ref) => {
    const num = props.value;
    const [value, setValue] = React.useState(isNil(num) || isNumberAndNaN(num) ? undefined : num?.toString());
    const refInput = React.useRef(null);
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    React.useEffect(() => {
        if (refInput.current) {
            refInput.current.focus();
            refInput.current.setSelectionRange(refInput.current.value.length, refInput.current.value.length);
        }
    }, [refInput]);
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const handleOnChange = (e) => {
        setValue(parseNumberListCustomFieldValue(e.target.value));
    };
    const formattedNumber = formatNumberListCustomFieldValue(value, props.numberFormat, false);
    return (_jsx("textarea", { onBlur: () => {
            refInput?.current?.focus();
        }, autoFocus: true, inputMode: "decimal", className: "min-h-[77px] resize-none bg-surface-default p-p50 rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default", onChange: handleOnChange, ref: refInput, value: formattedNumber, style: { width } }));
});
export const CustomColumnDateCellEditor = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState(props.value);
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const dateFormat = props.dateFormat;
    return (_jsx("div", { className: "inline-block h-[77px]", style: { width }, children: _jsx(SingleDatePicker, { minHeight: 77, selectedDate: value, onChange: (newVal) => {
                setValue(newVal);
                setTimeout(() => {
                    props.api.stopEditing();
                }, 10);
            }, native: false, dateFormat: dateFormat, alwaysOpen: true, fullWidth: true, autoFocus: true }) }));
});
