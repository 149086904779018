import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CrossIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_DASHBOARD_ROUTE } from 'utils/constants';
const TopNavigationBar = ({ name }) => {
    const navigationLocation = useLocation();
    const navigate = useNavigate();
    const onGoBackRoute = () => {
        const historyState = navigationLocation.state;
        if (historyState && historyState.from && historyState.params) {
            const lastURL = `${historyState.from}?${historyState.params}`;
            navigate(lastURL);
            return;
        }
        if (history.length > 1) {
            navigate(-1);
        }
        else {
            navigate(DEFAULT_DASHBOARD_ROUTE);
        }
    };
    return (_jsx("div", { className: classNames('ml-0 sm:ml-60', // Drawer width
        'fixed left-0 right-0 bg-white z-10 flex items-center', 'justify-between py-p50 border-b border-solid px-p70 border-border'), children: _jsxs("div", { className: "flex items-center gap-g60", children: [_jsx(IconButton, { icon: CrossIcon, onClick: onGoBackRoute, size: "compact" }), name && (_jsxs("p", { "data-testid": "TopNavigationbar-Name", className: classNames('typography-label-small text-content-weak'), children: ["\u00A0\u00A0", name] }))] }) }));
};
export default TopNavigationBar;
