import { ADD_FILTER_OPTION_TO_GRID_DASHBOARD, RESET_DASHBOARD, SET_SORT_FIELD, SET_SORT_IS_DESCENDING, UPDATE_SEARCH_MODEL } from 'actions/types/actionTypes';
import { INITIAL_COMPANY_SORT, INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
const initialDashboardState = {
    searchModel: INITIAL_SEARCH_MODEL,
    sortField: INITIAL_COMPANY_SORT.sortField,
    sortIsDescending: false
};
const dashboardReducer = (state = initialDashboardState, action) => {
    switch (action.type) {
        case UPDATE_SEARCH_MODEL:
            return {
                ...state,
                searchModel: action.payload === null
                    ? INITIAL_SEARCH_MODEL
                    : {
                        ...INITIAL_SEARCH_MODEL,
                        ...action.payload
                    }
            };
        case SET_SORT_FIELD:
            return {
                ...state,
                sortField: action.payload === ''
                    ? INITIAL_COMPANY_SORT.sortField
                    : action.payload
            };
        case SET_SORT_IS_DESCENDING:
            return {
                ...state,
                sortIsDescending: action.payload
            };
        case ADD_FILTER_OPTION_TO_GRID_DASHBOARD:
            return {
                ...state,
                searchModel: { ...state.searchModel, ...action.payload }
            };
        case RESET_DASHBOARD:
            return {
                ...initialDashboardState
            };
        default:
            return state;
    }
};
export default dashboardReducer;
