import gql from 'graphql-tag';
export const GET_WATCHLIST_FRAGMENT = gql `
  fragment Watchlist on CompanyWatchlist {
    owner {
      ... on User {
        entityUrn
        name
        email
      }
      ... on Customer {
        identifier
        name
      }
    }
    id
    entityUrn
    name
    userWatchlistType
    userRelevanceScoreInfluenceType
    sharedWithTeam
    updatedAt
    createdAt
  }
`;
export const getCompanyWatchlists = gql `
  query GetUseFetchWatchlists {
    getCompanyWatchlistsForTeam {
      ...Watchlist
    }
  }
  ${GET_WATCHLIST_FRAGMENT}
`;
