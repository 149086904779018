export const mergeCustomFieldValue = (existing, incoming, { readField, mergeObjects }) => {
    if (existing && incoming) {
        const existingUpdatedAt = readField('updatedAt', existing);
        const incomingUpdatedAt = readField('updatedAt', incoming);
        if (existingUpdatedAt &&
            incomingUpdatedAt &&
            new Date(existingUpdatedAt).getTime() >=
                new Date(incomingUpdatedAt).getTime()) {
            return existing;
        }
    }
    return mergeObjects(existing, incoming);
};
