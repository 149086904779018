import { useSplitTreatments } from '@splitsoftware/splitio-react';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import usePersistedZustandStore from 'stores/persistedZustandStore';
import useStore from 'stores/zustandStore';
import { PILOT_LIST } from 'utils/constants';
export const useMultipleFlags = (flagNames) => {
    const authState = useSelector((state) => state.auth);
    const userUrn = useStore((state) => state.userUrn);
    const localFlags = usePersistedZustandStore((state) => state.localFlags);
    const planType = useMemo(() => (PILOT_LIST.includes(userUrn) ? 'pilot' : 'paid'), [userUrn]);
    const flagsToFetch = !isEmpty(localFlags) ? [] : flagNames;
    /* `useTreatments` returns the evaluated treatments of the given list of split names.
     * While the SDK is not ready, treatments values are `control`.
     * If we send an empty list of flag names then nothing will be fetched but the rules of hooks will be followed. */
    const { treatments: fetchedTreatments } = useSplitTreatments({
        names: flagsToFetch,
        attributes: {
            plan_type: planType,
            email: authState.user?.email ?? '',
            user_urn: userUrn,
            customer_urn: authState.userMetadata?.customer_urn ?? ''
        }
    });
    const treatments = !isEmpty(localFlags) ? localFlags : fetchedTreatments;
    return flagNames.reduce((results, flagName) => ({
        ...results,
        [flagName]: {
            enabled: treatments[flagName]?.treatment === 'on'
        }
    }), {});
};
const useFlags = (flagName) => {
    const result = useMultipleFlags([flagName]);
    return result[flagName];
};
export default useFlags;
