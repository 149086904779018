export var ReportSourceView;
(function (ReportSourceView) {
    ReportSourceView["COMPANY"] = "COMPANY";
    ReportSourceView["PERSON"] = "PERSON";
    ReportSourceView["INVESTOR"] = "INVESTOR";
    ReportSourceView["COMPANY_SEARCH"] = "COMPANY_SEARCH";
    ReportSourceView["PERSON_SEARCH"] = "PERSON_SEARCH";
    ReportSourceView["COMPANY_LIST"] = "COMPANY_LIST";
    ReportSourceView["PERSON_LIST"] = "PERSON_LIST";
})(ReportSourceView || (ReportSourceView = {}));
export var ReportSourceSection;
(function (ReportSourceSection) {
    ReportSourceSection["FINANCING"] = "FINANCING";
    ReportSourceSection["CAP_TABLE"] = "CAP_TABLE";
    ReportSourceSection["OVERVIEW"] = "OVERVIEW";
    ReportSourceSection["TEAM"] = "TEAM";
    ReportSourceSection["SIMILAR_COMPANIES"] = "SIMILAR_COMPANIES";
    ReportSourceSection["TRACTION_METRICS"] = "TRACTION_METRICS";
    ReportSourceSection["CONTACT"] = "CONTACT";
    ReportSourceSection["CONNECTIONS"] = "CONNECTIONS";
    ReportSourceSection["LINEAGE"] = "LINEAGE";
    ReportSourceSection["INVESTMENTS"] = "INVESTMENTS";
    ReportSourceSection["CO_INVESTORS"] = "CO_INVESTORS";
    ReportSourceSection["DETAILS"] = "DETAILS";
})(ReportSourceSection || (ReportSourceSection = {}));
