/* Users ACTION TYPES */
export const SET_USER = 'SET_USER';
export const SET_USER_STATUS = 'SET_USER_STATUS';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_METADATA = 'SET_USER_METADATA';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const NEED_VERIFICATION = 'NEED_VERIFICATION';
/* Dashboard state ACTION TYPES */
export const RESET_ALL_ROW_COUNT_RETURNED = 'RESET_ALL_ROW_COUNT_RETURNED';
export const SET_SELECTED_ROW_IDS = 'SET_SELECTED_ROW_IDS';
export const RESET_SELECTED_ROW_IDS = 'RESET_SELECTED_ROW_IDS';
export const UPDATE_SEARCH_MODEL = 'UPDATE_SEARCH_MODEL';
export const SET_SORT_FIELD = 'SET_SORT_FIELD';
export const SET_SORT_IS_DESCENDING = 'SET_SORT_IS_DESCENDING';
export const ADD_FILTER_OPTION_TO_GRID_DASHBOARD = 'ADD_FILTER_OPTION_TO_GRID_DASHBOARD';
export const SET_ALL_ROWS_SELECTED = 'SET_ALL_ROWS_SELECTED';
export const SET_USER_API_KEY = 'SET_USER_API_KEY';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';
