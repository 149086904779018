import { useQuery } from '@apollo/client';
import { GET_WATCHLIST } from 'queries/getWatchlist';
import { useEffect, useMemo } from 'react';
import { getIdFromUrn } from 'utils/urn';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
const useFetchSingleWatchlist = ({ fetchPolicy, gridRef } = {
    fetchPolicy: 'cache-first',
    gridRef: undefined
}) => {
    const routeDetails = useDashboardLocation();
    const urn = routeDetails.urn;
    const isCompanyWatchlist = routeDetails.location === DashboardLocation.COMPANY_LIST;
    const id = getIdFromUrn(urn ?? null) || '';
    const { data: companyData, loading: companyWatchlistLoading, error: companyWatchlistError } = useQuery(GET_WATCHLIST, {
        variables: {
            idOrUrn: id
        },
        skip: !isCompanyWatchlist,
        fetchPolicy,
        returnPartialData: true,
        notifyOnNetworkStatusChange: true
    });
    const companyWatchlistData = useMemo(() => {
        if (companyData && isCompanyWatchlist) {
            return companyData?.getCompanyWatchlistByIdOrUrn;
        }
    }, [companyData, isCompanyWatchlist]);
    /*
      AG-Grid does not refresh the grid when the grid context changes, and we store the
      custom field metadata in the grid context. Hence this useEffect is needed to refresh the cells
      when a custom field's metatada is updated.
  
      For example, if we update the color/name of a select custom field option,
      we need to refresh the column to reflect the new name/color without a page refresh.
    */
    useEffect(() => {
        if (gridRef?.current?.api?.getEditingCells().length == 0) {
            gridRef?.current?.api?.refreshCells({
                columns: companyWatchlistData?.customFields?.map((cf) => cf.urn),
                force: true
            });
        }
    }, [companyWatchlistData?.customFields, gridRef]);
    return {
        companyWatchlist: companyWatchlistData,
        companyWatchlistLoading,
        companyWatchlistError
    };
};
export default useFetchSingleWatchlist;
