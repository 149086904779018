import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//give me a basic react component
import { useMutation } from '@apollo/client';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { ConnectionIntegrationType, CustomerIntegrationPrivacySetting } from '__generated__/graphql';
import CircleCheckFilledIcon from 'assets/harmonic-icons/circle-check-filled';
import GoogleIcon from 'assets/harmonic-icons/google';
import Button from 'harmonic-components/Button/Button';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import useUser from 'hooks/useUser';
import { UPDATE_CUSTOMER_INTEGRATION_MUTATION } from 'queries/updateCustomerIntegration';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SPLITS } from 'utils/constants';
import { authenticateGoogleIntegration } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import EmailCalendarTermsModal from './EmailCalendarTermsModal';
var AuthStatus;
(function (AuthStatus) {
    AuthStatus[AuthStatus["None"] = 0] = "None";
    AuthStatus[AuthStatus["GettingStarted"] = 1] = "GettingStarted";
    AuthStatus[AuthStatus["ReadyToSign"] = 2] = "ReadyToSign";
    AuthStatus[AuthStatus["Pending"] = 3] = "Pending";
    AuthStatus[AuthStatus["MissingScopes"] = 4] = "MissingScopes";
    AuthStatus[AuthStatus["Valid"] = 5] = "Valid";
    AuthStatus[AuthStatus["Scheduled"] = 6] = "Scheduled";
    AuthStatus[AuthStatus["Error"] = 7] = "Error";
})(AuthStatus || (AuthStatus = {}));
const endpoint = 'https://api.harmonic.ai/google_auth/authenticate';
const IntegrateGmailButton = ({ isIntegrated = false, type, onSuccess }) => {
    const { show, close } = useModal();
    const { enabled: restrictNetworkMappingMetadataOnly } = useFlags(SPLITS.restrictNetworkMappingMetadataOnly);
    const [updateCustomerIntegration] = useMutation(UPDATE_CUSTOMER_INTEGRATION_MUTATION);
    const { setIntegrationStatus } = useUser();
    const didPressSignIn = useGoogleLogin({
        onSuccess: (codeResponse) => receivedAuthResponse(codeResponse),
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly openid',
        redirect_uri: endpoint
    });
    const [authStatus, setAuthStatus] = React.useState(AuthStatus.ReadyToSign);
    const receivedAuthResponse = (authResponse) => {
        setAuthStatus(AuthStatus.Pending);
        if (!authResponse.scope.includes('gmail.readonly') ||
            !authResponse.scope.includes('calendar.readonly')) {
            setAuthStatus(AuthStatus.MissingScopes);
            toast.error('To set up this integration, you need to grant permissions for both email and calendar. Please try again.', { autoClose: false });
            return;
        }
        authenticateGoogleIntegration(authResponse.code)
            .then(async (response) => {
            const statusText = response.data.status;
            if (statusText === 'missing_scopes') {
                setAuthStatus(AuthStatus.MissingScopes);
                displayToast({
                    primaryText: 'Some permission scopes are missing. Please try again later',
                    mode: 'error'
                });
            }
            else if (statusText === 'success') {
                // TODO: Update authenticate endpoint to pass this in as part of initial request
                if (restrictNetworkMappingMetadataOnly) {
                    await updateCustomerIntegration({
                        variables: {
                            input: {
                                integrationType: ConnectionIntegrationType.GOOGLE,
                                privacySetting: CustomerIntegrationPrivacySetting.LIMITED
                            }
                        }
                    });
                }
                setAuthStatus(AuthStatus.Valid);
                setIntegrationStatus(ConnectionIntegrationType.GOOGLE, true);
                toast.success(_jsxs("p", { children: ["Google account connected successfully. Data may take up to 24 hours to appear in app. You may check sync status in your", ' ', _jsx(Link, { className: "underline", to: "/settings?t=emailCalendar", children: "Settings" }), "."] }), { autoClose: false });
                if (onSuccess) {
                    onSuccess();
                }
            }
        })
            .catch(() => {
            setAuthStatus(AuthStatus.Error);
            displayToast({
                primaryText: 'Some problem occured. Please try again later',
                mode: 'error'
            });
        });
    };
    const onClick = () => {
        show(_jsx(EmailCalendarTermsModal, { handleClose: close, onConfirmClick: () => {
                didPressSignIn();
                close();
            } }));
    };
    const isLoading = authStatus === AuthStatus.Pending;
    if (type === 'primary') {
        return (_jsx(Button, { leadingIcon: () => _jsx(GoogleIcon, { applyCurrentColor: true }), trailingIcon: isIntegrated
                ? () => (_jsx(CircleCheckFilledIcon, { className: "text-content-sentiment-positive", applyCurrentColor: true }))
                : undefined, isDisabled: isLoading || isIntegrated, loading: isLoading, type: isIntegrated ? 'secondary' : 'primary', size: "compact", emphasis: "high", label: isIntegrated ? 'Connected' : 'Connect Google account', onClick: onClick }));
    }
    return (_jsx(Button, { leadingIcon: GoogleIcon, trailingIcon: isIntegrated
            ? () => _jsx(CircleCheckFilledIcon, { applyCurrentColor: false })
            : undefined, isDisabled: isLoading || isIntegrated, loading: isLoading, type: "secondary", size: "compact", emphasis: "high", label: isIntegrated ? 'Connected' : 'Connect Google account', onClick: onClick }));
};
const AuthProviderWrapper = (props) => {
    return (_jsx(GoogleOAuthProvider, { clientId: "738524585869-nu2uafl27s7nfusqmr8val0opbh5tuv5.apps.googleusercontent.com", children: _jsx(IntegrateGmailButton, { ...props }) }));
};
export default AuthProviderWrapper;
