import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import Select from '../../../../../harmonic-components/Select/Select';
import { BoardContext } from '../../../../common/Board/BoardView';
export const CustomFieldSelect = (props) => {
    const { isDragging } = useContext(BoardContext);
    const { options, api, children, ...rest } = props;
    const getLabelFromValue = useCallback((value) => {
        return options.find((option) => option.urn === value)?.name ?? '';
    }, [options]);
    const getTagColorFromValue = useCallback((value) => {
        return (options.find((option) => option.urn === value)
            ?.color || 'neutral');
    }, [options]);
    const handleClose = useCallback(() => {
        api.stopEditing();
    }, [api]);
    return (_jsx(Select, { ...rest, initialFocus: true, alwaysOpen: true, multiple: true, filterable: true, onClose: handleClose, hideDropdown: isDragging, getLabelFromValue: getLabelFromValue, getTagColorFromValue: getTagColorFromValue, dropdownMaxHeight: "33vh", minHeight: 77, children: children }));
};
